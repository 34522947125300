
.game-body-container{
        display: grid;
        grid-gap: 10px;
        width: 100%;
}

.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 800px) {
    .game-body-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
}

@media (min-width: 1200px) {
    .game-body-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
    }
}
