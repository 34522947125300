.footer-container{
    margin-top: auto;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #d0d0d0;
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer-header{
    display: grid;
    width: 75%;
    grid-template-columns: repeat(2, 1fr);
}

.footer-header-links{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: rgb(118, 118, 118);
}

.footer-header-title div{
    padding-left: 10px;
    font-family: 'Montserrat', sans-serif;
    color: rgb(118, 118, 118);
    font-weight: 500;
}

.footer-disclaimer{
    margin-top: 15px;
    font-size: small;
    color: #505050;
    width: 75%;
}

.footer-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.footer-header-links * {
    color: inherit
}
.footer-header-links *:hover {
    cursor: pointer;
    color: inherit;
}

@media (min-width: 100px) {
    .footer-body{
        width:95%;
    }

    .footer-header-title{
        display: none;
    }
    
    .footer-header{
        display: block;
    }
}

@media (min-width: 800px) {
    .footer-body{
        width: 800px;
    }

    .footer-header-title{
        display: none;
    }
    
    .footer-header{
        display: block;
    }
}

@media (min-width: 1000px) {
    .footer-body{
        width: 1000px;
    }
    
    .footer-header{
        display: grid;
        width: 75%;
        grid-template-columns: repeat(2, 1fr);
    }

    .footer-header-title{
        display: flex;
        justify-content: center;
        align-items: center; 
        color: rgb(118, 118, 118);
    }
}

@media (min-width: 1200px) {
    .footer-body{
        width: 1200px;
    }

    .footer-header{
        display: grid;
        width: 75%;
        grid-template-columns: repeat(2, 1fr);
    }

    .footer-header-title{
        display: flex;
        justify-content: center;
        align-items: center; 
        color: rgb(118, 118, 118);
    }
}
