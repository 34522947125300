.no-games-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 2%;
    text-align: center;
}

.no-games-container img{
    width: 50%;
}

.no-games-container h3{
    font-weight: bold;
}

.no-games-container p{
    width: 90%;
}