.matchup-time{
    color: rgb(146, 146, 146);
    font-size: .8em;
    text-align: center;
}

.matchup-teams{
    display: grid;
    row-gap: 5px;
}

.matchup-card{
  animation: fadein .5s;
}

@keyframes fadein {
  from { opacity: 0}
  to   { opacity: 1}
}
