.about-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.about-card{
    border-bottom: 1px solid lightgray;
    padding-top: 20px;
    padding-bottom: 20px;
}

.about-card-left{
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.about-card-right{
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.about-card-image{
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-card-left .about-card-image img{
    width: 40%;
}

.about-card-right .about-card-image img{
    width: 90%;
}

.about-card-text{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.about-card h1{
  font-family: 'Montserrat', sans-serif;
  font-weight:700; 
  transition: 0.3s;
  font-size: 2em;
}

.about-team-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.about-team{
    border: none;
    padding: 0px;
}


.about-card-left .about-card-image img{
    width: 150px;
}

.about-card-right .about-card-image img{
    width: 200px;
}


.about-story{
    border-bottom: none;
}

@media (max-width: 599px) {
    .about-card {
        width: 95%;
        display: flex;
        flex-direction: column;
    }

    .about-card-right{
        display: flex;
        flex-direction: column-reverse
    }

    .about-welcome .about-card-image img{
        width: 40%;
        padding-top: 20px;
    }

    .about-thesis .about-card-image img{
        width: 60%;
    }

    .about-story .about-card-image img{
        width: 50%;
    }

    .about-support .about-card-image img{
        width: 40%;
        padding-top: 20px;
    }
}

@media (min-width: 600px) {
    .about-story{
        grid-template-columns: 1fr;
        width: 100%;
    }
    .about-card {
        width: 600px;
    }
}

@media (min-width: 800px) {
    .about-card {
        width: 800px;
    }
}

@media (min-width: 1000px) {
    .about-card {
        width: 1000px;
    }
}
