.construction-card{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    margin-top: 20px;
    text-align: center;
}

.construction-card * {
    padding-bottom: 20px;
}


.construction-card h1{
    padding: 0;
    margin: 0;
    font-weight: bolder;
    font-size: 2em;
}

.construction-card img {
    width: 90%;
}

@media (max-width: 799px) {
    .construction-card{
        width:95%;
    }

    .construction-card img {
        width: 80%;
    }
}

@media (min-width: 600px) {
    .construction-card{
        width: 600px;
    }
}


