.team-member-container{
    margin-top: 10px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 1px 6px rgba(144, 144, 144, 0.25);
}

.team-member-container h3{
    font-size: larger;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.team-member-container h4{
    font-size: medium;
    color: rgb(146, 146, 146);
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.team-member-container h5{
    font-size: medium;
}

.team-member-img{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-member-img img{
    width: 175px;
    height: 175px;
    border-radius: 4px;
}

.team-member-fact{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color:rgb(146, 146, 146);

}

.team-member-facts ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
}

.team-member-about-links *{
    padding-right: 10px;
}

@media (max-width: 599px) {
    .team-member-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .team-member-about{
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid lightgray;
    }

    .team-member-facts{
        width: 100%;
        padding-top: 10px;
    }
}

@media (min-width: 600px) {
    .team-member-container {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr;
    }

    .team-member-about{
        padding-left: 10px;
        border-right: 1px solid lightgray;
    }

    .team-member-facts{
        padding-left: 10px;
    }
}
