.home-container{
    width: 100%;
    height: 100%;
}

.home-welcome{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.home-welcome-text{
    height: 100%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-welcome-text-container{
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.home-welcome-image{
    width: 50%;
}

.home-welcome-image img{
    width: 100%;
}

.home-welcome h1{
    font-family: 'Montserrat', sans-serif;
    font-weight:700; 
}

.home-welcome-buttons * {
    margin-right: 10px;
}

.first-button{
    background-color: #E9640B;
    border: 1px solid #E9640B;
}

.first-button:hover{
    background-color: #cb5709;
    border: 1px solid #cb5709;
}


@media (max-width: 600px) {

    .home-welcome-buttons{
        padding-top: 20px;
    }
    .home-welcome-text-container{
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .home-welcome-text{
        width: 90%
    }

    .home-welcome-image{
        width: 100%;
    }

    .home-welcome{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 800px) {
    .home-welcome{
        width: 800px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media (min-width: 1000px) {
    .home-welcome{
        width: 1000px;
    }
}