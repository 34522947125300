.support-body{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.support-body-text{
    height: 50%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.support-body-text-container{
    display: flex;
    width: 95%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.support-body-image{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 300px;
}

.support-body-image img{
    width: 100%;
}

.support-body h1{
    font-family: 'Montserrat', sans-serif;
    font-weight:700; 
}

.support-body-buttons{
    padding-top: 20px;
}

.support-body-buttons * {
    margin-right: 10px;
}

.first-button{
    background-color: #E9640B;
    border: 1px solid #E9640B;
}

.first-button:hover{
    background-color: #cb5709;
    border: 1px solid #cb5709;
}


@media (max-width: 599px) {
    .support-body-buttons{
        padding-top: 20px;
    }
    .support-body-text-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .support-body-text{
        width: 80%;
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .support-body{
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .support-body-image{
        padding-top: 20px;
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .support-body{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

@media (min-width: 600px) {
    .support-body{
        width: 600px;
        display: flex;
        padding-top: 75px;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 800px) {
    .support-body{
        width: 800px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


@media (min-width: 1000px) {
    .support-body{
        padding-top: 100px;
        width: 1000px;
    }
}