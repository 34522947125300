.team-container{
    display: grid;
    grid-template-columns: 80% 20%;
    width: 100%;
    height: 100%;

}

.team-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: rgb(91, 91, 91);
}

.team-spread{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(91, 91, 91);
    font-family: 'Hind', sans-serif;
    font-size: small;
}

.team-info p{
    margin: 0;
    padding: 0;
    padding-left: 10px;
    font-size: 16px;
}

.team-container img{
    width: 30px;
    height: 30px;
}

.team-favorite-text{
    font-weight: 800;
    color: black;
}

.team-favorite-badge {
  background-color: rgb(68, 68, 68);
  color: white;
  padding: 1px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
}

