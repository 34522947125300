.game-container{
    padding-top: 10px;
}

.game-body{
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-between;
}

@media (min-width: 100px) {
    .game-body{
        width:95%;
    }
}

@media (min-width: 800px) {
    .game-body{
        width: 800px;
    }
}

@media (min-width: 1000px) {
    .game-body{
        width: 1000px;
    }
}

@media (min-width: 1200px) {
    .game-body{
        width: 1200px;
    }
}