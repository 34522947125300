.nav-text {
  font-family: 'Montserrat', sans-serif;
  font-weight:300; 
  transition: 0.3s;
}

.nav-text:hover {
  font-weight:600;
}

.navbar-brand{
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-brand img{
  max-width: 10%;
}

.navbar-brand div{
  padding-left: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}


