.game-header-title{
    padding: 0;
    margin: 0;
    font-weight: bolder;
    font-size: 2em;
}

.game-header-date{
    padding: 0;
    margin: 0;
    font-size:larger;
    width: 100%;
    font-style: italic;
}

.game-header-titles *{
    padding-bottom: 5px;
}


.game-header-card{
    padding: 10px;
}

.game-header-text{
    border-top: 1px solid rgb(179, 179, 179);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

